import React, { useEffect, useState, useCallback } from 'react';
import {
  GoogleMap,
  LoadScript,
  Marker,
  DirectionsRenderer,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import "bootstrap/dist/css/bootstrap.min.css";
import LyfGuardLogo from '../screens/assets/LyfGuardLogo.png';

// Later in your JSX

import axios from 'axios';
import Basicimg from "../screens/assets/th.jpeg"; // Adjust the path to your actual image location
import freezerimg from "../screens/assets/Freezer.jpeg"; // Adjust the path to your actual image location
import advanceimg from "../screens/assets/Advance.jpeg";

import { Link } from "react-router-dom";
import "./googlemap.css";
import styled from "styled-components";
import logo from "./assets/profileimage-removebg-preview.png";
import { FaAngleRight } from "react-icons/fa";

import { Modal, Button, Form, Card } from "react-bootstrap";
import { MdCurrencyRupee } from "react-icons/md";

const libraries = ["places"];
const containerStyle = { width: "100%", height: "100vh"};

const initialCenter = { lat: 0, lng: 0 }; // Use appropriate default coordinates


function MapComponent() {
  const [sourcePosition, setSourcePosition] = useState(initialCenter);
  const [destinationPosition, setDestinationPosition] = useState(null);
  const [directions, setDirections] = useState(null);
  const [totalDistance, setTotalDistance] = useState("");
  const [totalDuration, setTotalDuration] = useState("");
  const [sourceInput, setSourceInput] = useState("");
  const [destinationInput, setDestinationInput] = useState("");
  const [searchBoxSource, setSearchBoxSource] = useState(null);
  const [searchBoxDestination, setSearchBoxDestination] = useState(null);
  const [ambulanceType, setAmbulanceType] = useState([]);
  const [ammunitiesType, setAmmunitiesType] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({}); // Initialize form data
  const [paymentMethod, setPaymentMethod] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [locationFetched, setLocationFetched] = useState(false);
  const [amenities, setAmenities] = useState([]); // State to hold amenities
  const [loadingAmenities, setLoadingAmenities] = useState(true); // Loading state
  const [error, setError] = useState(null);
  const [amulanceerror, setambulancesetError] = useState(null);
const [selectedAmbulanceType, setSelectedAmbulanceType] = useState(null); // State to track the selected ambulance type
const [totalAmount, setTotalAmount] = useState(0); // State for total amount
  const [ambulanceAmounts, setAmbulanceAmounts] = useState({});
  const [selectedAmenity, setSelectedAmenity] = useState('');
  const [selectedAmbulanceId, setSelectedAmbulanceId] = useState(null);
  const [sourceAddress, setSourceAddress] = useState('');
  const [destinationAddress, setDestinationAddress] = useState('');
  const [ammunities, setAmmunities] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [bookingDetails, setBookingDetails] = useState({});
  const [emergencyType, setEmergencyType] = useState('');
  const [isEmergencyVisible, setIsEmergencyVisible] = useState(false);
  const [isPrivateVisible, setIsPrivateVisible] = useState(false);
  const [selectedType, setSelectedType] = useState(null);
  // Set these values when the user selects or enters the data
  

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  
  
  
  
  useEffect(() => {
    const fetchAmenities = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch('http://65.1.246.11/lyfguard/public/api/private-ambulance-get-amenity', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
  
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(`Error fetching amenities: ${errorData.message}`);
        }
  
        const data = await response.json();
        console.log('Fetched amenities:', data);
  
        if (Array.isArray(data.data.amenities)) {
          setAmenities(data.data.amenities);
        } else {
          console.error('Expected an array but got:', data.data.amenities);
          setAmenities([]);
        }
        setLoadingAmenities(false);
      } catch (error) {
        console.error(error);
        setError(error.message);
        setLoadingAmenities(false);
      }
    };
  
    fetchAmenities();
  }, []);
  
  // Fetching Ambulance Types based on amenity ID
 // Fetch Ambulance Types based on amenityId
 const fetchAmbulanceTypes = async (amenities) => {
  try {
    const token = localStorage.getItem('token');
    console.log('Token:', token);

    console.log('Fetching ambulance types for amenities:', amenities);

    const response = await fetch('https://dev.lyfguard.litspark.cloud/api/private-ambulance-get-ambulance-type-by-amenity', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ amenities }), // Use amenities key here
    });

    if (!response.ok) {
      const errorData = await response.json();
      console.error('Error response from API:', errorData);
      throw new Error(`Error fetching ambulance types: ${errorData.message}`);
    }

    const data = await response.json();
    console.log('Full API Response:', data);
    if(data?.data?.types){
    setAmbulanceType(data.data.types);
    }else{
      setAmbulanceType([])
    }

    // if (data && data.data && Array.isArray(data.data.ambulanceTypes)) {
    //   const amounts = {};
    //   data.data.ambulanceTypes.forEach((type) => {
    //     amounts[type.name] = type.amount; // Map ambulance types to their amounts
    //   });
    //   console.log('Mapped ambulance amounts:', amounts);
    //   setAmbulanceAmounts(amounts); // Assuming setAmbulanceAmounts is a state setter
    // } else {
    //   console.error('Ambulance types not found or incorrect response format:', data);
    //   setAmbulanceAmounts({});
    // }
  } catch (error) {
    console.error('Error fetching ambulance types:', error.message);
    setambulancesetError(error.message);
    setAmbulanceType([])
  }
};


const handleAmenityChange = (amenityId) => {
  if (!amenityId) return;

  console.log('Selected Amenity ID:', amenityId);
  fetchAmbulanceTypes(amenityId); // Fetch ambulance types for the selected amenity
  setSelectedAmenity(amenityId);
  localStorage.setItem("selectedAmenity", amenityId);
  
};
const handleAmbulanceTypeChange = (ambulanceId) => {
  if (!ambulanceId) return;

  console.log('Selected Ambulance Type:', ambulanceId);
  // You can now set the selected ambulance type and perform any other actions
  setSelectedAmbulanceType(ambulanceId);
  localStorage.setItem("selectedAmbulanceType", ambulanceId); // Assuming there's a state to handle selected type
};
// Define the function before using it


// Usage in JSX


// Get ambulance image based on selected ambulance type
const getAmbulanceImage = (type) => {
  switch (type) {
    case "Basic":
      return Basicimg;
    case "Freezer":
      return freezerimg;
    case "Advance":
      return advanceimg;
    default:
      return null;
  }
};
  
  const fetchLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          console.log("Latitude:", latitude, "Longitude:", longitude);
          // Do something with the location (e.g., update state)
        },
        (error) => {
          console.error("Error fetching location:", error);
        }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };
  

  // Fetch location
  useEffect(() => {
    if (locationFetched) return;

    const fetchLocation = () => {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            try {
              const { latitude, longitude } = position.coords;
              setSourcePosition({ lat: latitude, lng: longitude });

              const geocoder = new window.google.maps.Geocoder();
              geocoder.geocode(
                { location: { lat: latitude, lng: longitude } },
                (results, status) => {
                  if (status === window.google.maps.GeocoderStatus.OK && results[0]) {
                    const address = results[0].formatted_address;
                    setSourceInput(address);
                    console.log(`Location obtained: ${latitude}, ${longitude}`);
                    console.log(`Address obtained: ${address}`);
                  } else {
                    console.error("Error fetching address:", status);
                    setSourceInput("Address not found");
                  }
                }
              );
            } catch (err) {
              console.error(err);
            }
          },
          (error) => {
            console.error("Error getting location:", error);
          }
        );
        setLocationFetched(true);
      }
    };

    fetchLocation();
  }, [locationFetched]); // This ensures it runs only once to fetch the location

  // Early return for loading state and errors
  
  // const amenitie = [
  //   { id: 1, name: "Basic" },
  //   { id: 2, name: "Advanced" }
  // ];
  
  // const ambulanceAmount = {
  //   Basic: 1000,
  //   Advanced: 2000,
  //   Freezer: 2500
  // };
  

  const handleLocationError = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        alert("User denied the request for Geolocation.");
        break;
      case error.POSITION_UNAVAILABLE:
        alert("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        alert("The request to get user location timed out.");
        break;
      case error.UNKNOWN_ERROR:
        alert("An unknown error occurred.");
        break;
      default:
        alert("Something went wrong.");
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const AmbulanceItem = styled.a`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px;
    margin: 5px 0;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    text-decoration: none;
    color: #000;
    transition: background-color 0.3s, box-shadow 0.3s;

    &:hover {
      background-color: #f0f0f0;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    &.active {
      background-color: #007bff;
      color: #fff;
    }
  `;

  const AmbulanceImage = styled.img`
    width: 50px;
    height: 50px;
    border-radius: 5px;
    margin-bottom: 5px; /* Space below the image */
  `;

  const AmountText = styled.p`
    font-size: 14px;
    margin: 0;
    display: flex;
    align-items: center;
  `;

  const TotalDurationText = styled.p`
    font-size: 12px;
    color: #666;
    margin: 0;
    margin-bottom: 5px; /* Space below the duration text */


  `;

  const showAlertAndFetchLocation = () => {
    // Your logic for showing alert and fetching location
    alert('Fetching location...');
    // Code to fetch location
  };
  const handleConfirmBooking = async () => {
    // Construct booking data from inputs and localStorage
    const bookingData = {
      source: sourceInput || localStorage.getItem("sourceAddress"),
      destination: destinationInput || localStorage.getItem("destinationAddress"),
      amenity: selectedAmenity || localStorage.getItem("selectedAmenity"),
      ambulanceType: ambulanceType.map(type => type.id), // Map ambulanceType to IDs
      
      totalAmount: totalAmount || parseFloat(localStorage.getItem("calculatedFare")), // Ensure the amount is a number
      paymentMethod: paymentMethod || "cash", // Ensure payment method has a default value
    };
  
    // Log the data for debugging
    console.log("Booking Data:", bookingData);
  
    // Get the authorization token from localStorage or other secure location
    const authToken = localStorage.getItem("authToken"); // Change this to wherever you store your token
  
    // Check if the token is present
    if (!authToken) {
      alert("Authorization token not found. Please log in again.");
      return; // Exit the function if the token is not available
    }
  
    try {
      const response = await fetch("https://dev.lyfguard.litspark.cloud/api/emergency/private-ambulance-book", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${authToken}`, // Add authorization header
        },
        body: JSON.stringify(bookingData),
      });
  
      if (!response.ok) {
        // Log the response for debugging
        const errorResponse = await response.json(); // Capture the error response
        console.error(`Error: ${response.status}`, errorResponse);
        throw new Error(`Error: ${response.status} - ${errorResponse.message || response.statusText}`);
      }
  
      const responseData = await response.json();
      console.log("Booking successful", responseData);
  
      // Show success modal or navigate
      setShowModal(true);
    } catch (error) {
      console.error("Booking failed", error);
      alert("Booking failed. Please try again.");
    }
  };
  
  useEffect(() => {
    // Retrieve the ambulance type from localStorage when the component mounts
    const ambulanceType = localStorage.getItem('ambulanceType');
    if (ambulanceType) {
      setSelectedType(ambulanceType); // Set the state with the stored value
    }
  }, []);

  const handleAmbulanceSelection = (type) => {
    setSelectedType(type); // Update the selected type
    localStorage.setItem('ambulanceType', type); // Store the type in localStorage
  };
const toggleEmergency = () => {
  setIsEmergencyVisible(!isEmergencyVisible);
  setIsPrivateVisible(false); // Hide private ambulance when emergency is shown
};

// Function to handle toggling of private ambulance
const togglePrivate = () => {
  setIsPrivateVisible(!isPrivateVisible);
  setIsEmergencyVisible(false); // Hide emergency when private is shown
};

const selectAmbulanceType = (type) => {
  setSelectedAmbulanceType(type);
};

const handleEmergencyTypeChange = () => {
  // Your logic for handling emergency type change
};
  
 // Function to calculate the total amount
 const calculateTotalAmount = (ambulanceType, ambulanceAmounts, totalDistance, selectedAmenity) => {
  const basePrice = 50; // Base price is 50 Rs
  const pricePerKmAbove5 = 10; // Price for each km above 5 km is 10 Rs

  // Get the ambulance price based on the ambulance type
  const ambulancePrice = ambulanceAmounts[ambulanceType] || 0;

  // Get the amenity price based on the selected amenity
  const selectedAmenityObj = amenities.find(amenity => amenity.id === parseInt(selectedAmenity)); // Use parseInt for comparison
  const amenityPrice = selectedAmenityObj ? parseFloat(selectedAmenityObj.price) : 0; // Convert to float

  // Convert totalDistance (e.g. "10.2 km") to a number
  const distanceInKm = parseFloat(totalDistance);

  // Start with the base price + ambulance price + amenity price
  let totalAmount = basePrice + ambulancePrice + amenityPrice;

  // If distance is greater than 5 km, add extra distance cost
  if (distanceInKm > 5) {
    const extraDistance = distanceInKm - 5; // Calculate extra distance
    totalAmount += extraDistance * pricePerKmAbove5; // Add the extra distance cost
  }

  console.log("Total Amount Calculation:");
  console.log("Base Price:", basePrice);
  console.log("Ambulance Price:", ambulancePrice);
  console.log("Amenity Price:", amenityPrice);
  console.log("Total Distance:", distanceInKm);
  console.log("Final Total Amount:", totalAmount);

  return totalAmount;
};

// Fetch directions and calculate total amount when positions change
useEffect(() => {
  if (sourcePosition && destinationPosition) {
    const directionsService = new window.google.maps.DirectionsService();
    directionsService.route(
      {
        origin: sourcePosition,
        destination: destinationPosition,
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          const route = result.routes[0];
          const distance = route.legs[0].distance.text; // e.g. "10.2 km"
          setTotalDistance(distance);

          // Calculate and update the total amount
          const amount = calculateTotalAmount(ambulanceType, ambulanceAmounts, distance, selectedAmenity);
          setTotalAmount(amount); // Set the total amount in state
        } else {
          console.error(`Error fetching directions: ${status}`);
        }
      }
    );
  }
}, [sourcePosition, destinationPosition, ambulanceType, selectedAmenity]);

// Update the total amount whenever the selected amenity changes
useEffect(() => {
  if (totalDistance) {
    const amount = calculateTotalAmount(ambulanceType, ambulanceAmounts, totalDistance, selectedAmenity);
    setTotalAmount(amount); // Set the total amount in state
  }
}, [selectedAmenity, totalDistance]); // Recalculate when selected amenity or total distance changes
const updateFare = (totalAmount) => {
  // Store the calculated fare in local storage
  setTotalAmount(totalAmount);
  localStorage.setItem("calculatedFare", totalAmount);
};


  const onLoadSource = useCallback((ref) => setSearchBoxSource(ref), []);
  const onLoadDestination = useCallback(
    (ref) => setSearchBoxDestination(ref),
    []
  );

  const onSourceDragEnd = (event) => {
    const newPosition = { lat: event.latLng.lat(), lng: event.latLng.lng() };
    setSourcePosition(newPosition);
  };

  const onDestinationDragEnd = (event) => {
    const newPosition = { lat: event.latLng.lat(), lng: event.latLng.lng() };
    setDestinationPosition(newPosition);
  };

  const handlePlaceChange = (place, type) => {
    if (place && place.geometry && place.geometry.location) {
      const location = place.geometry.location;
      if (type === "source") {
        setSourcePosition({ lat: location.lat(), lng: location.lng() });
        setSourceInput(place.formatted_address);
      } else if (type === "destination") {
        setDestinationPosition({ lat: location.lat(), lng: location.lng() });
        setDestinationInput(place.formatted_address);
      }
    } else {
      console.warn(`No valid ${type} place found.`);
    }
  };

  const onSourcePlacesChanged = () => {
    if (searchBoxSource) {
      const places = searchBoxSource.getPlaces();
      if (places && places.length > 0) {
        handlePlaceChange(places[0], "source");
      } else {
        console.warn("No source place found.");
      }
    }
  };
  

  const onDestinationPlacesChanged = () => {
    if (searchBoxDestination) {
      const places = searchBoxDestination.getPlaces();
      if (places && places.length > 0) {
        handlePlaceChange(places[0], "destination");
      } else {
        console.warn("No destination place found.");
      }
    }
  };
  const bookingData = {
    ambulanceType: [
      {
        id: 2,
        slug: "pta",
        name: "PTA", // Ensure this is set correctly
        image: null,
        description: null,
        price: null,
        amenities: "1"
      },
      {
        id: 3,
        slug: "bls",
        name: "BLS", // Ensure this is set correctly
        image: null,
        description: null,
        price: null,
        amenities: "1"
      }
    ],
    amenity: selectedAmenity,
    fare: totalAmount,
  };
  
  localStorage.setItem('bookingData', JSON.stringify(bookingData));
  
  const handleBookNow = () => {
    const bookingData = {
      ambulanceType: [ambulanceType],
      amenity: selectedAmenity,
      fare: totalAmount,
    };
  
    localStorage.setItem('bookingData', JSON.stringify(bookingData));
    console.log("Stored Booking Data:", bookingData);
    setShowModal(true);
  };
  
  const handleBooking = () => {
    console.log("Booking confirmed!");
    setShowModal(false); // Close the modal after booking
    // Add your booking logic here (e.g., API call)
  };
 
  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('bookingData'));
    if (storedData) {
      if (Array.isArray(storedData.ambulanceType) && storedData.ambulanceType.length > 0) {
        setSelectedAmbulanceType(storedData.ambulanceType[0]); // Use the first item
      } // Ensure this is an object
      setSelectedAmenity(storedData.amenity);
      setTotalAmount(storedData.fare);
    }
  }, []);
  
  useEffect(() => {
    localStorage.setItem('bookingData', JSON.stringify({
      ambulanceType,
      amenity: selectedAmenity,
      fare: totalAmount,
    }));
  }, [ambulanceType, selectedAmenity, totalAmount]);
  
  const storedData = JSON.parse(localStorage.getItem('bookingData'));

// Use storedData in your modal
const ambulanceTypeName = storedData?.ambulanceType || 'N/A';
const amenityName = storedData?.amenity || 'N/A';
const fare = storedData?.fare || 'N/A';

  return (
    <LoadScript
      googleMapsApiKey="AIzaSyCLg_Oahf9q1keNmJoqHc_Uk4f0fu3YmxU"
      libraries={["places"]}
    >
      <div>
        {/* Apply the background styles here */}
        <div className="map-container container-fluid p-0 ">
          <div className="row g-0 flex-lg-row-reverse flex-column-reverse flex-sm-column">
            {/* Map Column for Android (first column on smaller screens) */}

            <div className="col-lg-5 col-lg-6 col-12 order-1 order-lg-2 ">
              <div className="map-background">
                <header className="main-headers ">
                  <button className="menu-button " onClick={toggleMenu}>
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                  </button>
                  <nav className={`menu-items ${isMenuOpen ? "open" : ""}`}>
                    <button className="close-button" onClick={closeMenu}>
                      X
                    </button>
                    <a href="/" className="menu-item" onClick={closeMenu}>
                      Home
                    </a>
                    <a
                      href="/aboutus"
                      className="menu-item"
                      onClick={closeMenu}
                    >
                      About
                    </a>
                    <a
                      href="/services"
                      className="menu-item"
                      onClick={closeMenu}
                    >
                      Services
                    </a>
                    <a
                      href="/contactus"
                      className="menu-item"
                      onClick={closeMenu}
                    >
                      Contact
                    </a>
                  </nav>
                  <h1 className="logo-text">Lyfguard</h1>
                  <Link
                    to="/maprofile"
                    state={{ fromEmergency: true }}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <img
                      src={logo}
                      alt="Lyfguard Logo"
                      className="logo-image mr-4"
                    />
                  </Link>
                </header>
                <div className="scrollable-container">
                  <div className="dailyrides" style={styles.container}>
                  <button
          onClick={() => handleAmbulanceSelection('emergency')}
          className={selectedType === 'emergency' ? 'selected paragraphname bg-light text-black' : 'paragraphname bg-light text-black'}
          style={styles.paragraph}
        >
          Emergency Ambulance
        </button>

        <button
          onClick={() => handleAmbulanceSelection('private')}
          className={selectedType === 'private' ? 'selected paragraphname bg-light text-black' : 'paragraphname bg-light text-black'}
          style={styles.paragraph}
        >
          Private Ambulance
        </button>
                  {/* Conditional Rendering Based on Selected Ambulance Type */}
                 

                    <p
                      className="paragraphname bg-light text-black"
                      style={styles.paragraph}
                    >
                      Mortuary Vehicle
                    </p>
                  </div>

                  <div className="inputfields ">
                  <StandaloneSearchBox
      onLoad={onLoadSource}
      onPlacesChanged={onSourcePlacesChanged}
    >
      <input
        type="text"
        placeholder="From"
        value={sourceInput}
        onFocus={showAlertAndFetchLocation} // Trigger alert and location fetch on focus
        onChange={(e) => setSourceInput(e.target.value)}
        className="form-control mb-2 p-3"
        style={{ fontSize: "14px" }}
      />
    </StandaloneSearchBox>
    {selectedType === 'emergency' && (
  <div className="mb-3"> {/* Margin bottom for spacing */}
    
    <select
      id="emergencyType"
      className="form-select" // Bootstrap class for styling select
      value={emergencyType}
      onChange={handleEmergencyTypeChange}
    >
      <option value="">Select Emergency Type</option>
      <option value="et">ET</option>
      <option value="emt">EMT</option>
    </select>
  </div>
)}

{selectedType === 'private' && ( <div>
             <StandaloneSearchBox
                      onLoad={onLoadDestination}
                      onPlacesChanged={onDestinationPlacesChanged}
                    >
                      <input
                        type="text"
                        placeholder="To"
                        value={destinationInput}
                        onChange={(e) => setDestinationInput(e.target.value)}
                        className="form-control mb-2 p-3 "
                        style={{ fontSize: "14px" }}
                      />
                    </StandaloneSearchBox>

                    <div
                      className="d-flex justify-content-between bg-light p-2"
                      style={{ borderRadius: "0.15rem" }}
                    >
                      <p className="me-0 mb-0 " style={{ fontSize: "14px" }}>
                        Total Distance
                      </p>
                      <p className="mb-0" style={{ fontSize: "14px" }}>
                        {totalDistance || ""} km
                      </p>
                    </div>

                      <div>
                      <div>
                     <Form className="mt-2 mb-2">
  <Form.Group controlId="AmmunitiesType">
  <select
  className="form-select p-2"
   id="amenitySelect"
  value={selectedAmenity}
  onChange={(e) =>{
    const selectedAmenityId = e.target.value;
    console.log("Selected Amenity:", selectedAmenityId);
    setSelectedAmenity(selectedAmenityId); // Set the selected amenity
    handleAmenityChange(selectedAmenityId); // Fetch ambulance types based on the selected amenity's ID
  }}
  style={{ fontSize: "14px" }}
>
  <option value="">Select an Amenity</option>
  {loadingAmenities ? (
    <option disabled>Loading amenities...</option>
  ) : error ? (
    <option disabled>Error: {error}</option>
  ) : (
    // Ensure you're accessing the `amenity.name`, not the whole object `amenity`
    amenities.map((amenity) => (
      <option key={amenity.id} value={amenity.id}>
        {amenity.name} - ₹{amenity.price} {/* Display name and price */}
      </option>
    ))
  )}
</select>


    <Form.Control.Feedback type="invalid" style={{ fontSize: "14px" }}>
      Please select an Ammunities.
    </Form.Control.Feedback>
  </Form.Group>
</Form>




{/* Render the available rides based on selected amenity and ambulance types */}
<div>
      <p className="text-white mt-4">Available Ambulance Types</p>
      {ambulanceType?.map((type) => (
        <div
          key={type.id}
          className={`ambulance-item ${selectedAmbulanceId === type.id ? 'active' : ''}`}
          onClick={() => handleAmbulanceTypeChange(type.id)} // Call handler on click
          style={{ cursor: 'pointer', padding: '10px', border: '1px solid white', marginBottom: '10px', backgroundColor: selectedAmbulanceId === type.id ? 'red' : 'transparent', }}
        >
          <h4>{type.name}</h4>
          <p>{type.slug}</p>
        </div>
      ))}

      {/* Display Selected Ambulance Type */}
      {selectedAmbulanceId && (
        <div className="mt-4">
          <p className="text-white">Selected Ambulance ID: {selectedAmbulanceId}</p>
        </div>
      )}
    </div>


  </div>

      
    

    {/* Separate Div for Total Amount */}
    <div className="d-flex justify-content-between bg-light p-2" style={{ borderRadius: '0.15rem', marginTop: '10px' }}>
        <p className="mb-0" style={{ fontSize: '14px' }}>
          Total Amount:
        </p>
        <p className="mb-0 ms-auto" style={{ fontSize: '14px' }}>
          <MdCurrencyRupee className="totalamount me-1" />
          {totalAmount} {/* Display the total amount from state */}
        </p>
      </div>


    {/* Separate Div for Total Amount */}
    </div>
    </div>)}
                  </div>
                  
                  <>
      {/* Button to book the ambulance */}
      <div className="d-flex justify-content-center">
        <Button
          variant="danger"
          className="w-50"
          onClick={handleBookNow} // Use the selected ambulance type for booking
        >
          Book Now
        </Button>
      </div>

      {/* Modal Popup */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
  <Modal.Header closeButton>
    <Modal.Title>Booking Summary</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <div
      style={{
        backgroundColor: 'rgb(6, 105, 81)',
        backgroundImage: `url(${LyfGuardLogo})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        borderRadius: '10px',
        padding: '20px',
      }}
    >
      <div className="container-fluid" style={{ height: "100%" }}>
        {/* Ambulance Image and Name */}
        <div className="d-flex">
          <img
            src={getAmbulanceImage() || "fallback-image-url.png"}
            style={{
              width: "50px",
              height: "50px",
              marginTop: "5%",
              marginLeft: "40%",
            }}
            alt="Ambulance"
          />
         <h3 className="ms-2 mb-0" style={{ fontSize: "14px", fontWeight: "bold", marginTop: "8%", color:"white" }}>
         {ambulanceType.name || "No Name Provided"}
          </h3>
        </div>

        {/* Booking Details */}
        <Card className="styled-card mt-4">
          <Card.Body className="card-content p-0">
            <div className="card-item">
              <strong className="key" style={{ fontSize: "14px" }}>PICKUP</strong>
              <span className="datapickup" style={{ fontSize: "14px" }}>
                {sourceInput || localStorage.getItem('sourceAddress')}
              </span>
            </div>
            <div className="card-item">
              <strong className="key" style={{ fontSize: "14px" }}>DROP</strong>
              <span className="datadrop" style={{ fontSize: "14px" }}>
                {destinationInput || localStorage.getItem('destinationAddress')}
              </span>
            </div>
            <div className="card-item">
              <strong className="key" style={{ fontSize: "14px" }}>AMENITY</strong>
              <span className="dataamenity" style={{ fontSize: "14px" }}>
              {selectedAmenity || localStorage.getItem('selectedAmenity') || "N/A"}
              </span>
            </div>
            <div className="card-item">
              <strong className="key" style={{ fontSize: "14px" }}>FARE</strong>
              <span className="datafare" style={{ fontSize: "14px" }}>
                <MdCurrencyRupee />
                {totalAmount || localStorage.getItem('calculatedFare') || "0"}
              </span>
            </div>
          </Card.Body>
        </Card>

        {/* Payment and Coupon Code Section */}
        <Card className="styled-card mb-3 mt-4 p-0">
          <Card.Body className="w-100">
            <Form.Group controlId="paymentMethod" className="mb-4 d-flex align-items-center w-100">
              <Form.Label className="styled-form-label me-3 mb-0" style={{ fontSize: "14px" }}>
                <strong>PAY BY</strong>
              </Form.Label>
              <Form.Control
                as="select"
                value={paymentMethod}
                onChange={(e) => setPaymentMethod(e.target.value)}
                style={{ fontSize: "14px", marginLeft: "16px" }}
              >
                <option value="offline">Offline</option>
                <option value="online">Online</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="couponCode" className="mb-1 d-flex align-items-center w-100">
              <Form.Label className="styled-form-label me-2" style={{ fontSize: "14px" }}>
                <strong>COUPON</strong>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter coupon code"
                value={couponCode}
                onChange={(e) => setCouponCode(e.target.value)}
              />
              <Button className="ms-2 mb-0">Apply</Button>
            </Form.Group>
          </Card.Body>
        </Card>

        {/* Booking Button */}
        <div className="row justify-content-center">
          <div className="col-md-6 col-sm-8 col-10 d-flex justify-content-center">
          <Button
  variant="danger"
  className="w-50"
  onClick={handleConfirmBooking} // Updated function name
>
  Confirm Booking
</Button>

          </div>
        </div>

      </div>
    </div>
  </Modal.Body>
</Modal>

    </>
                </div>
              </div>
            </div>
            {/* Input Fields Column */}
            <div
              className="col-lg-6 col-12 d-flex flex-column"
              // Adding border radius and overflow hidden
            >
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={sourcePosition}
                zoom={14}
              >
                <Marker
                  position={sourcePosition}
                  draggable={true}
                  onDragEnd={onSourceDragEnd}
                />
                {destinationPosition && (
                  <Marker
                    position={destinationPosition}
                    draggable={true}
                    onDragEnd={onDestinationDragEnd}
                  />
                )}
                {directions && <DirectionsRenderer directions={directions} />}
              </GoogleMap>
            </div>
          </div>
        </div>
      </div>
    </LoadScript>
  );
}

export default MapComponent;
const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "7rem",
    flexWrap: "wrap", // Ensures responsiveness
    marginLeft: "5px",
    fontSize: "14px",
  },
  paragraph: {
    padding: "10px 10px", // Add padding for better spacing
    margin: "0 23px", // Consistent margin
    borderRadius: "5px", // Rounded corners for a modern look
    transition: "background-color 0.3s", // Smooth transition for hover effect
  },
  link: {
    textDecoration: "none",
    color: "inherit",
    textAlign: "center",
  },
};
