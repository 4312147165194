import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import logo from './assets/profileimage-removebg-preview.png';
import { FaAngleRight,   } from "react-icons/fa";
import "../screens/Maprofile.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import { SiWebmoney } from "react-icons/si";
import { FcContacts } from "react-icons/fc";
import { MdSignalWifiStatusbar3Bar } from "react-icons/md";

const Maprofile = () => {
  const location = useLocation();
  const [phone, setPhone] = useState("");
  const [userName, setUserName] = useState('');
  const [rideHistory, setRideHistory] = useState([]);
  const [showHistory, setShowHistory] = useState(false);

  useEffect(() => {
    const storedPhone = localStorage.getItem('phone');
    if (storedPhone) {
      setPhone(storedPhone);
    }

    const storedName = localStorage.getItem('name');
    if (storedName) {
      setUserName(storedName);
    }

    const storedHistory = JSON.parse(localStorage.getItem('rideHistory'));
    if (storedHistory) {
      setRideHistory(storedHistory);
    }
  }, []);

  const handleSeeAllRides = () => {
    setShowHistory(true);
  };

  const handleClose = () => {
    setShowHistory(false);
  };

  return (
    <Container fluid className="map-background d-flex align-items-center justify-content-center">
      <Row className="profile-container p-5 shadow-lg">
        <Col xs={12} className="text-center">
          <img src={logo} alt="Lyfguard Logo" className="logo-image img-fluid mb-3" />
          <p className="name mb-1">{userName}</p>
          <p className="names mb-3">+91-{phone}</p>
        </Col>

        <Col xs={12}>
          <ul className="list-unstyled text-center">
            <li className="rides text-primary hover-effect" onClick={handleSeeAllRides}>
              See all rides
            </li>
            <li className="ola d-flex justify-content-between align-items-center hover-effect">
              
              
            <SiWebmoney /> <span style={{ marginRight: '52%' }}> Lyfguard Money</span> <FaAngleRight />
            </li>
            <li className="ola d-flex justify-content-between align-items-center hover-effect">
  <FcContacts style={{ margin: 0, padding: 0 }} />
  <span style={{ marginRight: '45%' }}>Emergency Contacts</span>
  <FaAngleRight />
</li>

            <li className="ola d-flex justify-content-between align-items-center hover-effect">
             <MdSignalWifiStatusbar3Bar /> <span style={{ marginRight: '38%' }}> Lyfguard Wifi Credential</span> <FaAngleRight />
            </li>
          </ul>
        </Col>

        <Col xs={12}>
          <li className="rides mt-0 text-danger text-center">Log Out</li>
        </Col>
      </Row>

      {/* Modal for Ride History */}
      <Modal show={showHistory} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Ride History</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {rideHistory.length > 0 ? (
            <ul className="list-unstyled">
              {rideHistory.map((ride, index) => (
                <li key={index}>
                  {ride.destination} - {ride.date}
                </li>
              ))}
            </ul>
          ) : (
            <p>No ride history available.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Maprofile;
